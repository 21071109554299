<template>
  <div>
    <v-stepper vertical non-linear v-model="step">
      <v-stepper-step step="1" editable :complete="step1Complete">
        Gäste auswählen
        <div class="text-caption">
          Wähle zwei Gäste um diese zusammenzuführen.
        </div>
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-row>
          <v-col sm="6" class="pr-2">
            <v-autocomplete
              label="Gast 1 auswählen"
              :items="guestSelectionItems"
              v-model="selectedGuest"
              clearable
            />
          </v-col>
          <v-col sm="6">
            <v-autocomplete
              label="Gast 2 auswählen"
              :items="guestSelectionItems"
              v-model="selectedMember"
              clearable
            />
          </v-col>
        </v-row>
        <div class="subheading">Vorschläge für die Zusammenführung</div>
        <div v-if="similarityMapLoading">
          <div>Vorschläge werden geladen...</div>
          <v-progress-linear indeterminate />
        </div>
        <div v-else-if="numGuestProposals > 0">
          <div class="text-caption">
            Vorschläge werden anhand von ähnlichen Namen ermittelt. Die
            Auflistung ist möglicherweise unvollständig und kann Elemente
            enthalten die nicht relevant sind.
          </div>
          <v-chip
            v-for="id in guestProposals"
            :key="id"
            @click="proposalClick(id)"
            class="ma-1"
            >{{ personById(id).name }}</v-chip
          >
        </div>
        <div v-else>
          Keine Vorschläge gefunden. Über das Eingabefeld kann trotzdem ein Gast
          ausgewählt und in den nachfolgenden Schritten mit einem Mitglied
          zusammengeführt werden.
        </div>
        <v-btn
          :disabled="!step1Complete"
          @click.native="step = 2"
          class="mt-3"
          color="primary"
          >Nächster Schritt</v-btn
        >
      </v-stepper-content>

      <v-stepper-step step="2" :editable="step1Complete">
        Vorschau &amp; Bestätigung
        <div class="text-caption">Vorschau der Änderungen und Bestätigung</div>
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-row v-if="step1Complete">
          <v-col cols="6" class="pa-1">
            <person-detail-card
              v-if="selectedGuestData"
              :person="selectedGuestData"
              :editable="false"
              :closeable="false"
              :title="`${selectedGuestData.name} (Gast)`"
            />
          </v-col>
          <v-col cols="6" class="pa-1">
            <person-detail-card
              v-if="selectedMemberData"
              :person="selectedMemberData"
              :editable="false"
              :closeable="false"
              :title="`${selectedMemberData.name} (Mitglied)`"
            />
          </v-col>
        </v-row>

        <v-row justify="center" class="mb-3">
          <v-col class="text-h3" style="text-align: center" cols="2"
            >&darr;</v-col
          >
          <v-col class="text-h3" style="text-align: center" cols="2"
            >&darr;</v-col
          >
        </v-row>

        <v-row justify="center">
          <v-col cols="6" class="pa-1">
            <person-detail-card
              v-if="mergedMember.name !== undefined"
              :person="mergedMember"
              :closeable="false"
              @edit="showEditDialog = true"
            />
          </v-col>
        </v-row>
        <v-alert class="mt-3" type="warning" :value="true">
          Nach der Zusammenführung werden die Daten vom einen Gast automatisch
          gelöscht. Alle besuchten und geleiteten Touren werden auf den
          bleibenden Gast übertragen. Diese Aktion kann nicht Rückgängig gemacht
          werden!
        </v-alert>
        <v-btn class="mt-2 ml-0" color="primary" @click="performMerge"
          >Gäste zusammenführen</v-btn
        >
      </v-stepper-content>
    </v-stepper>
    <v-dialog
      scrollable
      persistent
      v-model="showEditDialog"
      max-width="75%"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <person-edit-card
        v-if="mergedMember.name !== undefined"
        :person="mergedMember"
        @close="showEditDialog = false"
        @save="
          (m) => {
            mergedMember = m;
            showEditDialog = false;
          }
        "
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import debounce from 'lodash/debounce'

import { createNotification } from '@/utils'

import PersonService from '@/services/person'

import PersonDetailCard from '@/components/person/PersonDetailCard'
import PersonEditCard from '@/components/person/PersonEditCard'
import FindSimilarWorker from 'worker-loader!./findSimilar.worker.js' // eslint-disable-line import/no-webpack-loader-syntax
import { usePersonStore } from '@/stores/person'

export default {
  name: 'MemberMergeTool',
  components: { PersonDetailCard, PersonEditCard },
  props: {},
  data() {
    return {
      step: 1,
      similarityMapLoading: false,
      similarityMap: {},
      selectedGuest: null,
      selectedMember: null,
      mergedMember: {},
      showEditDialog: false,
    }
  },
  computed: {
    step1Complete() { return this.selectedGuest !== null && this.selectedMember !== null },
    guestSelectionItems() { return this.guestList.map((obj) => { return { value: obj.id, text: obj.name } }) },
    memberSelectionItems() { return this.memberList.map((obj) => { return { value: obj.id, text: obj.name } }) },
    guestProposals() { return Object.keys(this.similarityMap).map(Number) },
    numGuestProposals() { return this.guestProposals.length },
    selectedMemberData() { return this.personById(this.selectedMember) },
    selectedGuestData() { return this.personById(this.selectedGuest) },
    ...mapState(usePersonStore, {
      guestList: 'guestList',
      memberList: 'memberList',
      personById: 'getById',
    }),
  },
  watch: {
    memberList() { this.createSimilarityMap() },
    guestList() { this.createSimilarityMap() },
    selectedGuest(val) { val && this.selectedMember && this.getMergePreview() },
    selectedMember(val) { val && this.selectedGuest && this.getMergePreview() },
  },
  mounted() {
    this.createSimilarityMap()
  },
  methods: {
    createSimilarityMap: debounce(function () {
      this.similarityMapLoading = true
      const worker = new FindSimilarWorker()
      worker.postMessage([this.guestList, this.guestList, 0.85])
      worker.onmessage = (event) => {
        const mapping = event.data
        for (const key in mapping) {
          const elem = mapping[key]
          for (const otheridx of elem) {
            delete mapping[otheridx]
          }
        }

        this.similarityMap = mapping
        this.similarityMapLoading = false
      }
    }, 100),
    getMergePreview: debounce(function () {
      PersonService.merge(this.selectedMember, this.selectedGuest)
        .then(member => {
          this.mergedMember = member
        })
    }, 100),
    proposalClick(id) {
      this.selectedGuest = id
      const candidates = this.similarityMap[this.selectedGuest]
      this.selectedMember = candidates ? candidates[0] : null
    },
    performMerge() {
      PersonService.merge(this.selectedMember, this.selectedGuest, { preview: false })
        .then((res) => {
          return Promise.all([
            res,
            usePersonStore().updateOrCreate(this.mergedMember),
          ])
        })
        .then(res => {
          this.step = 1

          usePersonStore().getPerson(this.selectedMember)

          // Update Similarity Map,  Remove Guest & Force update Member data
          this.$delete(this.similarityMap, this.selectedGuest)

          this.selectedMember = null
          this.selectedGuest = null
          createNotification('Gäste erfolgreich zusammengeführt.', 'success')
        })
        .catch(() => {
          createNotification('Fehler beim zusammenführen.', 'error')
        })
    },
  },
}
</script>
