<template>
  <v-container fluid>
    <div class="text-h4 mb-2">Tools</div>
    <collapser :initial-open="false">
      <template slot="title">Gäste und Mitglieder zusammenführen</template>
      <template slot="subtitle"
        >Tritt ein Gast dem SAC bei können hier seine Daten mit den SAC Daten
        zusammengeführt werden.</template
      >
      <template slot="content"><guest-member-merge-tool /></template>
    </collapser>
    <collapser :initial-open="false">
      <template slot="title">Doppelte Gäste zusammenführen</template>
      <template slot="subtitle"
        >Doppelt erfasste Gäste können hier zusammengeführt werden.</template
      >
      <template slot="content"><person-merge-tool /></template>
    </collapser>
    <collapser :initial-open="false">
      <template slot="title">Teilnehmerlisten exportieren</template>
      <template slot="subtitle"
        >Export von Teilnehmer- / Mitgliederlisten der einzelnen
        Tourengruppen.</template
      >
      <template slot="content"><member-export-tool /></template>
    </collapser>
    <collapser :initial-open="false">
      <template slot="title">Statistiken der Tourengruppen</template>
      <template slot="subtitle"
        >Export von detailierten Tourenstatistiken der einzelnen
        Tourengruppen</template
      >
      <template slot="content"><export-org-statistics /></template>
    </collapser>
  </v-container>
</template>

<script>

import GuestMemberMergeTool from '@/components/tools/GuestMemberMergeTool'
import PersonMergeTool from '@/components/tools/PersonMergeTool'
import MemberExportTool from '@/components/tools/MemberExportTool'
import ExportOrgStatistics from '@/components/tools/ExportOrgStatistics'
import Collapser from '@/components/Collapser'

export default {
  name: 'Tools',
  components: { GuestMemberMergeTool, PersonMergeTool, MemberExportTool, ExportOrgStatistics, Collapser },
  props: {},
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>
