<template>
  <v-card>
    <v-card-text>
      <p>
        Exportiere die Teilnehmerdaten deiner Tourengruppe von allen Touren der
        letzten 12 Monate. Die Liste beinhaltet die Informationen aller
        Teilnehmer welche in den letzten 12 Monaten an mindestens einer Tour
        teilgenommen haben.
      </p>
      <v-form v-model="valid">
        <v-row>
          <v-col sm="12" md="4">
            <v-autocomplete
              label="Tourengruppe"
              required
              :items="organisations"
              v-model="organisation"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col sm="1" />
          <v-col sm="11" md="2">
            <v-btn @click.stop="startExport" :disabled="!valid"
              >Exportieren</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'pinia'

import downloadService from '@/services/download'
import { handleApiError } from '@/utils.js'
import { useEventStore } from '@/stores/event'

export default {
  name: 'MemberExportTool',
  data() {
    return {
      organisation: undefined,
      valid: false,
      rules: {
        required: (value) => !!value || 'Dieses Feld ist erforderlich',
      },
    }
  },
  computed: {
    ...mapState(useEventStore, {
      organisations: state => state.meta.organisations,
    }),
  },
  methods: {
    startExport() {
      downloadService.download(`/export/participants/${this.organisation}`)
        .catch(error => {
          handleApiError(error, 'Fehler beim Laden der Datei')
        })
    },
  },
}
</script>
