import axios from '@/plugins/axios'

function sign(url, params = {}) {
  const eUC = encodeURIComponent
  params = Object.keys(params).map(k => `${eUC(k)}=${eUC(params[k])}`).join('&')
  url = params ? `${url}?${params}` : url
  return axios.post('/api/v1/auth/sign', { url })
}

function download(endpoint, params = {}) {
  const baseUrl = window.location.origin
  const url = `${baseUrl}${endpoint}`
  return sign(url, params)
    .then(response => {
      window.location = response.data.url
    })
}


export default {
  sign,
  download,
}
